<template>
  <div id="fixed-fields">
    <div class="title">Onboarding</div>

    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i class="icon-home"></i></el-breadcrumb-item>
        <el-breadcrumb-item>Onboarding</el-breadcrumb-item>
        <el-breadcrumb-item>Kartlar</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="content-wrapper">
      <div class="form-section">
        <div class="part">
          <div class="header">Kartlar:</div>

          <div v-for="(item, index) in Onboardings" :key="index" class="card-container">
            <div class="card-edit-section">
              <div class="top">
                <div class="mid-text">Kart {{ index + 1 }}</div>
                <div class="button-group">
                  <div class="arrow-button" @click="moveItem(index, 'up')" v-if="index > 0">
                    <i class="el-icon-arrow-up"></i>
                  </div>
                  <div class="arrow-button" @click="moveItem(index, 'down')" v-if="index < Onboardings.length - 1">
                    <i class="el-icon-arrow-down"></i>
                  </div>
                  <div class="delete-icon" @click="deleteItem(index)">
                    <i class="icon-delete-fixed"></i>
                  </div>
                </div>
              </div>

              <div class="images-container">
                <!-- Web Image -->
                <div class="image-wrapper">
                  <div class="image-title">Resim (En boy oranı 9/16)</div>
                  <input type="file" @change="uploadFile($event, index, 'web')" style="display: none" :ref="'file_web_' + index" />
                  <div v-if="!item.ImgUrl" class="image upload-button" @click="uploadFiles(index, 'web')">
                    <i class="icon-camera-2"></i>
                    <div>Fotoğraf Yükle</div>
                  </div>
                  <div v-else class="image image-preview" :style="{ backgroundImage: 'url(' + item.ImgUrl + ')' }">
                    <div class="image-overlay">
                      <i class="icon-close remove-icon" @click.stop="removeImage(index, 'web')"></i>
                    </div>
                  </div>
                </div>

                <!-- Arka Plan Resmi -->
                <div class="image-wrapper">
                  <div class="image-title">Arka Plan Resmi</div>
                  <input type="file" @change="uploadFile($event, index, 'background')" style="display: none" :ref="'file_background_' + index" />
                  <div v-if="!item.BackgroundImage" class="image upload-button" @click="uploadFiles(index, 'background')">
                    <i class="icon-camera-2"></i>
                    <div>Arka Plan Yükle</div>
                  </div>
                  <div v-else class="image image-preview" :style="{ backgroundImage: 'url(' + item.BackgroundImage + ')' }">
                    <div class="image-overlay">
                      <i class="icon-close remove-icon" @click.stop="removeImage(index, 'background')"></i>
                    </div>
                  </div>
                </div>
              </div>

              <div class="colors-container">
                <div class="color-picker-wrapper">
                  <div class="color-title">Metin Rengi</div>
                  <el-color-picker color-format="hex" v-model="Onboardings[index].TextColor" show-alpha></el-color-picker>
                </div>
                <div class="color-picker-wrapper">
                  <div class="color-title">Arka Plan Rengi</div>
                  <el-color-picker color-format="hex" v-model="Onboardings[index].Color" show-alpha></el-color-picker>
                </div>
              </div>

              <el-input type="text" :rows="4" placeholder="Başlık..." v-model="Onboardings[index].Title"> </el-input>
              <quill-editor ref="myQuillEditor" :options="editorOption" v-model="Onboardings[index].SubTitle"> </quill-editor>
            </div>
          </div>

          <div class="button-group-admin">
            <el-button class="confirmReportAdmin" type="primary" @click="update"><i class="icon-send"></i>Kaydet</el-button>
            <el-button class="confirmReportAdmin" type="primary" @click="addItem"><i class="icon-plus"></i>Madde Ekle</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      languageList: [],
      ValidationErrors: [],
      Onboardings: [],
      editorOption: {
      
      },
    };
  },
  async beforeMount() {
    await this.getData();
  },
  methods: {
    async getData() {
      var res = await this.$client.post("/AboutUs/GetOnboardings");
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.Onboardings = res.data.Data.Items;
      }
    },
    addItem() {
      this.Onboardings.push({
        ID: null,
        Title: null,
        SubTitle: null,
        ImgUrl: null,
        BackgroundImage: null,
        TextColor: null,
        Color: null,
      });
    },
    deleteItem(index) {
      this.Onboardings.splice(index, 1);
    },
    async update() {
      var payload = { Items: this.Onboardings };
      var res = await this.$client.post("/AboutUs/UpdateOnboardings", payload);
      this.ValidationErrors = res.data.ValidationErrors;
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.$message.success(res.data.Message);
        await this.getData();
      }
    },
    async uploadFile(event, index, type) {
      let file = event.target.files[0];
      var fd = new FormData();
      fd.append("file", file);
      var res = await this.$client.post("/Account/UploadFile?type=3&RelatedItemId=" + this.Onboardings[index].ID, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.data.HasError != undefined && res.data.HasError == true) {
        this.$message.error(res.data.Message);
      } else {
        const updatedCards = [...this.Onboardings];
        if (type === 'web') {
          updatedCards[index].ImgUrl = res.data.Item.Url;
        } else if (type === 'background') {
          updatedCards[index].BackgroundImage = res.data.Item.Url;
        }
        this.Onboardings = updatedCards;
        this.$forceUpdate();
      }
      this.$refs[`file_${type}_${index}`][0].value = null;  
    },
    uploadFiles(index, type) {
      this.$refs[`file_${type}_${index}`][0].click();
    },
    removeImage(index, type) {
      const updatedCards = [...this.Onboardings];
      if (type === 'web') {
        updatedCards[index].ImgUrl = null;
      } else if (type === 'background') {
        updatedCards[index].BackgroundImage = null;
      }
      this.Onboardings = updatedCards;
      this.$forceUpdate();
    },
    moveItem(index, direction) {
      if (direction === 'up' && index > 0) {
        const temp = this.Onboardings[index];
        this.$set(this.Onboardings, index, this.Onboardings[index - 1]);
        this.$set(this.Onboardings, index - 1, temp);
      } else if (direction === 'down' && index < this.Onboardings.length - 1) {
        const temp = this.Onboardings[index];
        this.$set(this.Onboardings, index, this.Onboardings[index + 1]);
        this.$set(this.Onboardings, index + 1, temp);
      }
    },
  },
};
</script>

<style lang="less">
#fixed-fields {
  .content-wrapper {
    margin-top: 20px;
  }

  .card-container {
    display: flex;
    gap: 30px;
    margin-bottom: 30px;
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  }

  .card-edit-section {
    flex: 3;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .part {
    .header {
      margin-bottom: 10px;
    }
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      .mid-text {
        font-weight: 500;
        font-size: 16px;
        color: #44566c;
      }

      .button-group {
        display: flex;
        gap: 8px;
        align-items: center;
      }

      .delete-icon {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ff4d4f;
        border-radius: 8px;
        cursor: pointer;
        transition: all 0.3s;

        i {
          color: white;
          font-size: 16px;
        }

        &:hover {
          transform: scale(1.05);
          background: #ff7875;
        }
      }
    }

    .images-container {
      display: flex;
      gap: 20px;
      margin-bottom: 20px;
    }

    .image-wrapper {
      .image-title {
        font-weight: 500;
        font-size: 14px;
        color: #44566c;
        margin-bottom: 8px;
      }
    }

    .image {
      cursor: pointer;
      width: 135px;
      height: 240px;
      background-position: center;
      background-size: cover !important;
      background: #44566c;
      opacity: 0.6;
      border-radius: 10px;
      box-shadow: 0px 16px 32px rgba(169, 194, 209, 0.55), 0px 8px 16px rgba(169, 194, 209, 0.45);
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;

      &.upload-button {
        opacity: 0.6;
        div,
        i {
          color: #fff;
          font-weight: 500;
          font-size: 15px;
          align-self: center;
        }
      }

      &.image-preview {
        opacity: 1;
        .image-overlay {
          position: absolute;
          top: 0;
          right: 0;
          padding: 5px;
          display: none;
        }

        &:hover .image-overlay {
          display: block;
          background: rgba(0, 0, 0, 0.15);
          border-top-right-radius: 10px;
        }

        .remove-icon {
          width: 16px;
          height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ff4d4f;
          font-size: 16px;
          cursor: pointer;
          transition: all 0.3s;

          &:hover {
            transform: scale(1.1);
            color: #ff7875;
          }
        }
      }
    }
  }

  .colors-container {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }

  .color-picker-wrapper {
    .color-title {
      font-weight: 500;
      font-size: 14px;
      color: #44566c;
      margin-bottom: 8px;
    }
  }

  .arrow-button {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #1890ff;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s;

    i {
      color: white;
      font-size: 16px;
    }

    &:hover {
      transform: scale(1.05);
      background: #40a9ff;
    }
  }
}
</style>
